import React from "react";
import PuffLoader from "react-spinners/PuffLoader";
import { useSpinDelay } from 'spin-delay';

export default function LoaderComponent() {
    const showSpinner = useSpinDelay(true, { delay: 500, minDuration: 200 });

    if (!showSpinner) {
        return null;
    }

    return <div className="absolute h-full w-full top-0 left-0 z-50 bg-opacity-30 bg-gray-300">
        <div className="relative h-full">
            <div className="absolute top-1/2 left-1/2">
                <PuffLoader color="#467E92" size="50px"/>
            </div>
        </div>
    </div>
}
