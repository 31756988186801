import React, { useEffect, useState } from 'react';
import UsersList from '../components/appShell/usersList';
import { getCollection } from "../firebase/firestoreUtils";
import { capitalize } from "../utils";
import { searchUsers } from "../utils/search";
import userDefaultIcon from "../images/user_default.svg";
import GatsbyConfig from "../../gatsby-config";
import { navigate } from "gatsby"
import PropTypes from 'prop-types';

const Users = ({ id }) => {
    const [users, setUsers] = useState([]);
    const [searchString, setSearchString] = useState("");

    useEffect(() => {
        fetchData(searchString);
    }, [ searchString ]);

    async function fetchData(searchString) {
        try {
            console.log("Start fetch users, searchString: " + searchString);
            const data = await search(searchString);
    
            const usersMapped = data.map(user => {
                const firstName = user.FirstName && user.FirstName !== "" ? user.FirstName : "Empty";
                const lastName = user.LastName && user.LastName !== "" ? user.LastName : "Empty";
                return { 
                    id: user.UserId,
                    name: firstName + " " + lastName,
                    imageUrl: userDefaultIcon,
                    schemaVersion: user.SchemaVersion,
                    fields: {
                        "First Name": firstName,
                        "Last Name": lastName,
                        "Birthday": user.DateOfBirth ?? "Unknown",
                        "Gender": user.Gender ?? "Unknown",
                        "Onboarding Passed": user.PassedOnboardings && user.PassedOnboardings.find(p => p.Key === "InitialOnboarding") != null ? "Yes" : "No",
                        "Phone Number": user.Phone ?? "Unknown",
                        "Schema Version": user.SchemaVersion,
                    }
                }});
            setUsers(usersMapped);
        }
        catch(e) {
            console.log("Users loading error: " + e.toString());
            console.error(e);
        }
    }

    const selectedUser = users.find(u => u.id === id);
    
    return <UsersList users={users} 
                      onSearch={setSearchString}
                      selectedUser={selectedUser} 
                      onUserSelected={(id) => navigate(`/users/${id}`)}/>;
};

async function search(searchString) {
    if (GatsbyConfig.siteMetadata.firebase_use_emulator == "true") {
        const baseQuery = getCollection("user_settings");
        let queries = [ baseQuery.limit(50) ];
        if (searchString) {
            console.log("Try to find by UID, FirstName and LastName");
            const capitilized = capitalize(searchString.toLowerCase());
            const lowerCased = searchString.toLowerCase();
            const values = [searchString, lowerCased, capitilized, `${capitilized} `, `${lowerCased} `];
            const uidQuery = baseQuery.where("UserId", "==", searchString);
            const firstNameQuery = baseQuery.where("FirstName", "in", values);
            const lastNameQuery = baseQuery.where("LastName", "in", values);
            queries = [ uidQuery, firstNameQuery, lastNameQuery ];
        }

        let data = [];
        for (const query of queries) {
            const response = await query.get();
            data = [ ...data, ...response.docs.map(d => d.data()) ];
        }

        return data.reduce((result, current) => {
            if (result.find(d => d.UserId == current.UserId) != null) {
                return result;
            }
            return [ ...result, current ];
        }, []);
    }

    console.log("Try to find user using index search");
    return await searchUsers(searchString);
}

Users.propTypes = {
    id: PropTypes.string
}

export default Users;
