import algoliasearch  from "algoliasearch";
import GatsbyConfig from "../../gatsby-config";

const client = algoliasearch(GatsbyConfig.siteMetadata.algoliaAppId, GatsbyConfig.siteMetadata.algoliaApiKey);

const indicies = {}

const USERS_INDEX_NAME = "users-search"

async function search(indexName, value) {
    let index = indicies[indexName];
    if (!index) {
        index = client.initIndex(indexName); 
        indicies[indexName] = index;
    }
    try {
        // default result limit is 20
        console.log(`Start search index=${indexName}, value=${value}`);
        const result = await index.search(value);
        console.log(`Found ${result.nbHits} results`);
        return result.hits;
    } catch (e) {
        console.error(`Error searching index=${indexName}, value=${value}`);
        return [];
    }
}

function getIndexName(indexName) {
    return GatsbyConfig.siteMetadata.firestore_configuration + "-" + indexName;
}

export async function searchUsers(userName) {
    return search(getIndexName(USERS_INDEX_NAME), userName);
}