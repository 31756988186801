import React, { useState, useEffect, useRef } from "react"
import { SearchIcon, ChevronLeftIcon } from "@heroicons/react/solid"
import UserDetails from "./userDetails/userDetails"
import PropTypes from 'prop-types';
import useDebounce from "../../hooks/useDebounce";
import scrollIntoView from 'scroll-into-view-if-needed';

const UsersList = ({ users = [], onSearch, onUserSelected, selectedUser }) => {
  const [searchString, setSearchString] = useState("");
  const [showList, setShowList] = useState(false);
  const debouncedSearchString = useDebounce(searchString, 500);
  const selectedRef = useRef(null);

  useEffect(() => {
    setShowList(selectedUser == null);
  }, [ selectedUser ]);

  useEffect(() => {
    if (selectedUser && users.length > 0 && users.includes(selectedUser)) {
      scrollIntoView(selectedRef.current, {
        scrollMode: 'if-needed',
        block: 'center',
        inline: 'center',
        behavior: 'smooth',
      });
    }
  }, [ selectedRef, selectedUser, users ]);

  const listContainerClassName = showList ? "" : "hidden";

  const userCellBackground = (selectedUser, user) => {
    if (selectedUser == user) {
      return " bg-gray-200";
    }
    return " hover:bg-gray-50";
  }

  useEffect(() => {
    onSearch(searchString);
  }, [ debouncedSearchString ]);

  return (
    <div className="flex-1 relative z-0 flex overflow-hidden">
      <aside className={"xl:visible xl:order-first xl:flex xl:flex-col flex-shrink-0 w-99 xl:w-96 border-r border-gray-200 " + listContainerClassName}>
        <div className="px-6 pt-6 pb-4">
          <h2 className="text-lg font-medium text-gray-900">Users</h2>
          <p className="mt-1 text-sm text-gray-600">
            {"The list shows first 100 users. Use search to find a specific user."}
          </p>
          <div className="mt-6 flex space-x-4" action="#">
            <div className="flex-1 min-w-0">
              <label htmlFor="search" className="sr-only">
                Search
              </label>
              <div className="relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <SearchIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  onChange={(e) => setSearchString(e.target.value)}
                  type="search"
                  className="focus:ring-pink-500 focus:border-pink-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                  placeholder="Search by UID, First Name or Last Name"
                />
              </div>
            </div>
          </div>
        </div>
        <nav className="flex-1 min-h-0 overflow-y-scroll h-full" aria-label="Users">
          <ul role="list" className="relative z-0 divide-y divide-gray-200">
                {users.map(user => (
                <li key={user.id}>
                  <div className={"relative px-6 py-5 flex items-center space-x-3" + userCellBackground(selectedUser, user)}>
                    <div className="flex-shrink-0">
                      <img className="h-10 w-10 rounded-full" src={user.imageUrl} alt="" />
                    </div>
                    <div className="flex-1 min-w-0">
                      <a className="focus:outline-none" onClick={() => onUserSelected(user.id)}>
                        {/* Extend touch target to entire panel */}
                        <span className="absolute inset-0" aria-hidden="true" />
                        <p className="text-sm font-medium text-gray-900">{user.name}</p>
                        <p className="text-sm text-gray-500 truncate">{"Id: " + user.id}</p>
                      </a>
                    </div>
                    {selectedUser == user && <div className="w-0 h-0" ref={selectedRef}/>}
                  </div>
                </li>
                ))}
          </ul>
        </nav>
      </aside>
      {selectedUser && 
      <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last">
         <nav className="flex items-start px-4 py-3 sm:px-6 lg:px-8 xl:hidden cursor-pointer" aria-label="Breadcrumb">
              <div className="inline-flex items-center space-x-3 text-sm font-medium text-gray-900" onClick={() => setShowList(true)}>
                <ChevronLeftIcon className="-ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                <span>Users</span>
              </div>
            </nav>
        <UserDetails profile={selectedUser}/>
      </main>
      }
    </div>
  )
}

UsersList.propTypes = {
  users: PropTypes.array,
  onSearch: PropTypes.func,
  getUserMedications: PropTypes.func,
  getAlarms: PropTypes.func,
  startChat: PropTypes.func,
  onUserSelected: PropTypes.func,
  selectedUser: PropTypes.object,
  loadLogs: PropTypes.func
}

export default UsersList
