import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getCollection } from "../../../firebase/firestoreUtils";
import { localizeTimestamp } from "../../../utils";
import Loader from "../../loader";
import { ArrowRightIcon, ArrowDownIcon } from "@heroicons/react/outline";

const Notifications = ({ userId, schemaVersion, activeApp }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [notificationsLists, setNotificationsLists] = useState([]);

    useEffect(() => {
        const getNotifications = async () => {
            try {
                setIsLoading(true);
                let query = getCollection("local_notifications_lists")
                    .where("UserId", "==", userId)
                    .where("SchemaVersion", "==", schemaVersion);
                const notificationsLists = await query.get();
                const notificationsListsData = notificationsLists.docs
                    .map(doc => { return { Id: doc.id, ...doc.data() } })
                    .sort((a, b) => {
                        if (activeApp == null) {
                            return 0
                        }
                        if (a.AppId == activeApp.Id) {
                            return -1;
                        } else if (b.AppId == activeApp.Id) {
                            return 1; 
                        }
                        return 0;
                    });
                setNotificationsLists(notificationsListsData);
            }
            catch (e) {
                console.log("Notifications lists loading error");
                console.error(e);
            }
            finally {
                setIsLoading(false);
            }
        };

        getNotifications();
    }, []);

    return <div className="h-full flex-grow-0 overflow-y-auto mx-4 px-6">
        {isLoading && <Loader />}
        {!isLoading && notificationsLists.length == 0 && <div className="text-xl my-3">Nothing to show here!</div>}
        {notificationsLists.length > 0 && <div>
            {notificationsLists.map(list => {
                return <NotificationsList key={list.Id} list={list} activeApp={activeApp}/>
            })}
        </div>}
    </div>
}

const NotificationsList = ({ list, activeApp }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    if (!isExpanded) {
        return <div className="flex flex-col my-1">
            <div className="flex flex-row items-center" onClick={() => setIsExpanded(!isExpanded)}>
                <div className={"text-xl font-semibold mr-2 my-2"}>{list.Id}</div>
                {activeApp != null && list.AppId == activeApp.Id && <div className={"text-xl font-semibold mr-2 my-2 text-wedgewood-600"}>From last active app</div>}
                {isExpanded ? <ArrowDownIcon className="h-6 w-6" aria-hidden="true"/> : <ArrowRightIcon className="h-6 w-6" aria-hidden="true"/>}
            </div>
        </div>
    }

    return (<div className="flex flex-col my-1">
        <div className="flex flex-row items-center" onClick={() => setIsExpanded(!isExpanded)}>
                <div className={"text-xl font-semibold mr-2 my-2"}>{list.Id}</div>
                {activeApp != null && list.AppId == activeApp.Id && <div className={"text-xl font-semibold mr-2 my-2 text-wedgewood-600"}>From last active app</div>}
                {isExpanded ? <ArrowDownIcon className="h-6 w-6" aria-hidden="true"/> : <ArrowRightIcon className="h-6 w-6" aria-hidden="true"/>}
        </div>

        <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2 text-wedgewood-600">Application Id: </div>    
            <div className="">{list.AppId}</div>
        </div>

        <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2 text-wedgewood-600">Schema version: </div>    
            <div className="mr-2">{list.SchemaVersion}</div>
        </div>

        {list.Notifications.length > 0 && <>
                <div className="text-sm font-semibold mr-2 text-wedgewood-600">Current Notifications: </div>
                {list.Notifications.map((notification, i) => {
                    return <div className="flex flex-col px-3 my-1" key={i}>
                        <div className="flex flex-row items-center">
                            <div className="text-sm font-semibold mr-2">Date time: </div>    
                            <div className="mr-2">{localizeTimestamp(notification.DateTime, activeApp?.SmartDevice.TimeZone)}</div>
                        </div>
                        <div className="flex flex-row items-center">
                            <div className="text-sm font-semibold mr-2">Body: </div>    
                            <div className="mr-2">{notification.Body}</div>
                        </div>
                        <div className="flex flex-row items-center">
                            <div className="text-sm font-semibold mr-2">Critical: </div>    
                            <div className="mr-2">{notification.IsCritical ? "Yes" : "No"}</div>
                        </div>
                        <div className="flex flex-row items-center border-b">
                            <div className="text-sm font-semibold mr-2">Notification Id: </div>    
                            <div className="mr-2">{notification.NotificationId}</div>
                        </div>
                    </div>
                })}
            </>}
    </div>)
}

NotificationsList.propTypes = {
    list: PropTypes.object.isRequired,
    activeApp: PropTypes.object
}

Notifications.propTypes = {
    userId: PropTypes.string.isRequired,
    schemaVersion: PropTypes.number.isRequired,
    activeApp: PropTypes.object
}

export default Notifications;