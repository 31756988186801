import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import GatsbyConfig from '../../../../gatsby-config';
import { functions, storage } from "../../../firebase";
import { downloadFile } from "../../../utils";
import { RefreshIcon, CloudDownloadIcon, DocumentDownloadIcon, QuestionMarkCircleIcon } from "@heroicons/react/outline";
import parse from 'date-fns/parse';
import Loader from "../../loader";

const Logs = ({ userId, schemaVersion }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showHelp, setShowHelp] = useState(false);
    const [logs, setLogs] = useState([])
    const canRequestLogs = schemaVersion >= 7

    const listAllLogs = async () => {
        try {
            setIsLoading(true);
            setError(null);
            const path = GatsbyConfig.siteMetadata.firestore_configuration + "/" + userId + "/logs/";
            console.log("Start loading logs for: " + path);
            const response = await storage.ref().child(path).listAll();
            const logsData = response.items.map(i => {
                i.$date = tryParseDate(i.name);
                return i;
            });
            setLogs(logsData.sort(sortByDate));
        }
        catch (e) {
            console.log("Load logs error");
            console.error(e);
            setError(e.toString());
        }
        finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        listAllLogs();
    }, [])

    const requestLogs = async () => {
        try {
            setError(null);
            let data = JSON.stringify({ configuration: GatsbyConfig.siteMetadata.firestore_configuration, userId: userId });
            const callable = functions.httpsCallable("requestAutomaticFeedback");
            await callable({ input: data });
        }
        catch (e) {
            console.error(e);
            setError(e.message)
        }
    }
    
    const download = async (logEntry) => {
        const url = await logEntry.getDownloadURL();
        downloadFile(url, logEntry.name)
    }

    return <div className="h-full flex-grow-0 overflow-y-auto my-2 flex flex-col mx-4">
        {isLoading && <Loader />}
        {error && <div className="text-alert-red text-base mx-1 my-2">{"Error: " + error}</div>}
        <div className="text-xl mr-1 mx-4 my-3 text-gray-500 break-words">{"Storage full path: "+ GatsbyConfig.siteMetadata.firestore_configuration + "/" + userId + "/logs/"}</div>
        <div className="flex flex-col mx-4 overflow-auto flex-1">
            {logs.length > 0 && logs.map(l => <div key={l.fullPath} className="flex flex-row py-2 items-center border-b border-gray-200">
                <DocumentDownloadIcon className="h-5 w-5 mr-2"/>
                <div>{l.name}</div>
                <div className="text-base underline ml-2 self-center cursor-pointer text-wedgewood-500" onClick={() => download(l)}>Download</div>
            </div>)}
            {!isLoading && logs.length == 0 && <div className="text-xl my-3">Nothing to show here!</div>}

        </div>
        <div className="flex flex-row my-3 mx-4 items-center">
                {canRequestLogs && <button onClick={requestLogs} 
                    className="text-sm text-center font-semibold rounded-full text-white bg-wedgewood-600 flex flex-row items-center px-6 py-2 mr-3">
                        <CloudDownloadIcon className="h-6 w-6 mr-1"/>
                        <div>Request logs</div>
                </button>}
                <button onClick={listAllLogs} 
                    className="text-sm text-center font-semibold rounded-full text-white bg-wedgewood-600 flex flex-row items-center px-6 py-2 mr-3">
                        <RefreshIcon className="h-6 w-6 mr-1"/>
                        <div>Refresh</div>
                </button>
                <QuestionMarkCircleIcon className="h-6 w-6 mr-1 hidden 2xl:block" onClick={() => setShowHelp(!showHelp)}/>
                {showHelp && <div className="truncate flex-shrink hidden 2xl:block">You can use VSCode with &quot;Filter lines&quot; extension installed to filter and search logs.</div>}
        </div>
    </div>
}

function sortByDate(a, b) {
    const aDate = a.$date;
    const bDate = b.$date;
    if (aDate == null && bDate == null) {
        return 0;
    }
    if (aDate != null && bDate == null) {
        return -1
    }
    if (bDate != null && aDate == null) {
        return 1
    }
    return aDate > bDate ? 1 : -1;
}

function tryParseDate(name) {
    try {
        const regex = /(.+)_(feedback|chat).zip/mg
        const match = regex.exec(name);
        if (match && match.length > 0) {
            const value = match[1];
            const date = parse(value, "yyyy-MM-dd_HH_mm_ss", new Date());
            return date;
        }
        return null;
    }
    catch (e) {
        console.log("Error during parsing logs file name: " + name);
        console.error(e);
        return null;
    }
}

export default Logs;

Logs.propTypes = {
    userId: PropTypes.string.isRequired,
    schemaVersion: PropTypes.number.isRequired,
}