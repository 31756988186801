import React, { useEffect, useState } from 'react';
import Chat from "./chat"
import { classNames } from "../../../utils"
import PropTypes from 'prop-types';
import Medications from './medications';
import Alarms from './alarms';
import Logs from './logs';
import Profile from './profile';
import Apps from './apps';
import Places from './places';
import Notifications from './notifications';
import { getCollection } from "../../../firebase/firestoreUtils";

const tabs = [
  { name: "Profile", content: (profile, activeApp) => <Profile profile={profile} activeApp={activeApp} /> },
  { name: "Apps", content: (profile) => <Apps userId={profile.id} /> },
  { name: "Medications", content: (profile) => <Medications userId={profile.id} schemaVersion={profile.schemaVersion} /> },
  { name: "Alarms", content: (profile, activeApp) => <Alarms userId={profile.id} timeZone={activeApp?.SmartDevice.TimeZone} schemaVersion={profile.schemaVersion} /> },
  { name: "Notifications", content: (profile, activeApp) => <Notifications userId={profile.id} schemaVersion={profile.schemaVersion} activeApp={activeApp}/> },
  { name: "Places", content: (profile, activeApp) => <Places userId={profile.id} timeZone={activeApp?.SmartDevice.TimeZone} /> },
  { name: "Logs", content: (profile) => <Logs userId={profile.id} schemaVersion={profile.schemaVersion}/> },
  { name: "Chat", content: (profile) => <Chat userId={profile.id} schemaVersion={profile.schemaVersion} /> },
]

const UserDetails = ({ profile }) => {
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [activeApp, setActiveApp] = useState(null);

  // set default tab
  useEffect(() => {
    setSelectedTab(tabs[0]);
    setActiveApp(null);

    const fetchData = async () => {
      try {
        const userId = profile.id;
        const apps = await getCollection("apps")
          .where("ActiveUserId", "==", userId)
          .orderBy("MyHeartBeat", "desc")
          .limit(1)
          .get();
        if (apps.docs.length > 0) {
          const doc = apps.docs[0];
          let lastActiveApp = { Id: doc.id, ...doc.data() };
          const result = await getCollection("smart_devices").doc(lastActiveApp.SmartDeviceId).get();
          lastActiveApp = { ...lastActiveApp, SmartDevice: result.data() }
          setActiveApp(lastActiveApp);
        }
      }
      catch(e) {
        console.log("Load user last active app error");
        console.error(e);
      }
    }
    fetchData();
  }, [profile]);

  return (<article className="h-screen flex flex-col">
    <div>
      <div>
        {profile.coverImageUrl && <img
          className="h-32 w-full object-cover lg:h-32"
          src={profile.coverImageUrl}
          alt=""
        />}
        {!profile.coverImageUrl && <div
          className="h-32 w-full object-cover lg:h-32 bg-wedgewood-500"
        />}
      </div>
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
          <div className="flex">
            <img
              className="h-24 w-24 rounded-full sm:h-32 sm:w-32 bg-white"
              src={profile.imageUrl}
              alt=""
            />
          </div>
          <div className="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
            <div className="sm:hidden 2xl:block mt-6 min-w-0 flex-1">
              <h1 className="text-2xl font-bold text-gray-900 truncate">
                {profile.name}
              </h1>
            </div>
          </div>
        </div>
        <div className="hidden sm:block 2xl:hidden mt-6 min-w-0 flex-1">
          <h1 className="text-2xl font-bold text-gray-900 truncate">
            {profile.name}
          </h1>
        </div>
      </div>
    </div>

    {/* Tabs */}
    <div className="mt-6 sm:mt-2 2xl:mt-5">
      <div className="border-b border-gray-200">
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map(tab => (
              <div
                key={tab.name}
                href={tab.href}
                className={classNames(
                  selectedTab == tab
                    ? "border-pink-500 text-gray-900"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                  "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={tab.current ? "page" : undefined}
                onClick={() => setSelectedTab(tab)}
              >
                {tab.name}
              </div>
            ))}
          </nav>
        </div>
      </div>
    </div>

    {/* profile tab */}
    {selectedTab && selectedTab.content(profile, activeApp)}
  </article>)
}

UserDetails.propTypes = {
  profile: PropTypes.object
}

export default UserDetails
