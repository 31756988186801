import React from "react"
import MainLayout from "../components/mainLayout";
import { ArrowCircleLeftIcon } from "@heroicons/react/solid"
import { navigate } from "gatsby"

const NotEnoughPermissions = () => {
    return <MainLayout
                page={<div className="flex flex-col mt-10 items-center mx-10 text-center">
                        <div className="text-3xl font-bold">Access denied</div>
                        <div className="mt-6">Sorry, you do not have enough permissions to view the page.</div>
                        <button onClick={() => { navigate(-1) }} 
                                className="text-sm text-center font-semibold rounded-full text-white bg-wedgewood-600 flex flex-row items-center px-6 py-2 my-6" >
                                    <ArrowCircleLeftIcon className="h-6 w-6 mr-3"/>
                                    <div>Go back</div>
                        </button>
                    </div>}
            />
}

export default NotEnoughPermissions;