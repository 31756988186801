import React from "react"
import { Router } from "@reach/router";
import PrivateRoute from "../components/privateRoute";
import MainLayout from "../components/mainLayout";
import gatsbyConfig from "../../gatsby-config";
import Chats from '../pagesComponents/chats'
import Chat from '../pagesComponents/chat'
import Users from '../pagesComponents/users'
import { MANAGE_USERS } from "../permissionConstants";
import Login from "../pagesComponents/login";
import NotEnoughPermissions from "../pagesComponents/403";

const Index = function () {
    return <MainLayout page={<div className="flex flex-col mt-10 mx-10">
            <div className="text-3xl font-bold text-center w-auto">{gatsbyConfig.siteMetadata.title}
        </div>
    </div>}/>;
}

const ChatsIndex = function (props) {
    return <MainLayout page={<Chats {...props}/>} {...props} />
}

const ChatComponent = function (props) {
    return <MainLayout page={<Chat {...props}/>} {...props} />
}

function UsersIndex(props) {
    return <MainLayout page={<Users {...props}/>} {...props} />
}

export default function Home() {
    return <div>
        <Router>
            <PrivateRoute path="/" component={Index} />
            <PrivateRoute path="chats/" component={ChatsIndex} permission={MANAGE_USERS} />
            <PrivateRoute path="chats/:id" component={ChatComponent} permission={MANAGE_USERS} />
            <PrivateRoute path="users/" component={UsersIndex} permission={MANAGE_USERS} />
            <PrivateRoute path="users/:id" component={UsersIndex} permission={MANAGE_USERS} />
            <Login path="login" />
            <NotEnoughPermissions path="403" />
        </Router>
    </div>
}
