import React from "react";
import { useDispatch } from 'react-redux'
import { loginWithEmail, loginWithGoogle } from "../store/userDataSlice"
import { navigate } from "gatsby";
import GoogleIcon from "../components/google_icon";

const Login = () => {
    const dispatch = useDispatch()
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [error, setError] = React.useState("");

    async function signInWithEmailAndPassword() {
        try {
            setError("");
            await dispatch(loginWithEmail({ email, password })).unwrap();
            navigate("/");
        } catch (error) {
            console.error(error)
            setError(error.message);
        }
    }

    async function signInWithGoogle(e) {
      e.preventDefault();
      try {
          setError("");
          await dispatch(loginWithGoogle()).unwrap();
          navigate("/");
      } catch (error) {
          console.error(error)
          setError(error.message);
      }
    }

    async function onSubmit(e) {
        e.preventDefault();
        await signInWithEmailAndPassword()
    }

    return <form className="flex flex-col rounded-md mx-auto my-2 shadow-md items-center justify-start max-w-md bg-primary" onSubmit={onSubmit}>
        <h1 className="flex-auto font-bold py-5 text-2xl">Dose Medbox</h1>

        <div className="bg-wedgewood-600 bg-opacity-50 h-px my-1 mx-4 self-stretch" />

        <button onClick={signInWithGoogle} className="flex flex-nowrap bg-white border border-gray-400 rounded items-center p-2 my-1.5" type="button" >
          <GoogleIcon className="flex-auto mr-2" width={25} height={25}/>
          <div className="flex-auto font-light text-gray-400">Sign in with Google</div>
        </button>

        <div className="bg-wedgewood-600 bg-opacity-50 h-px my-1 mx-4 self-stretch" />

        <p className="text-sm font-medium text-gray-700 self-start mx-5 my-1">Email</p>
        <input className="mt-1 focus:ring-wedgewood-500 focus:border-wedgewood-500 shadow-sm sm:text-sm border-gray-300 rounded-md self-stretch mx-5 my-1 px-2 py-2" placeholder="email@example.com" type="text" value={email} onChange={event => {
                setEmail(event.target.value);
            }}/>

        <p className="text-sm font-medium text-gray-700 self-start mx-5 my-1">Password</p>
        <input className="mt-1 focus:ring-wedgewood-500 focus:border-wedgewood-500 shadow-sm sm:text-sm border-gray-300 rounded-md self-stretch mx-5 my-1 px-2 py-2" placeholder="********" type="password" value={password} onChange={event => {
                setPassword(event.target.value);
            }}/>

        <button className="self-stretch p-2 border border-wedgewood-600 ring-2 ring-wedgewood-600 font-semibold rounded-full text-white bg-wedgewood-600 select-none focus:outline-none px-2 py-2 mx-5 my-3" type="submit">
          Log in
        </button>

        <div className="bg-wedgewood-600 bg-opacity-50 h-px mt-1 mb-7 mx-4 self-stretch" />
        {error && <div className="text-sm font-medium text-alert-red self-center mx-5 my-1 text-center">{error}</div>}
    </form>
};

export default Login