import React, { useState } from "react";
import { Link } from "gatsby";
import { useDispatch, useSelector } from "react-redux";
import { navigate } from "gatsby";
import { createChat, getChats, getDashboardChatData } from "../store/chatsSlice";

const Chats = () => {
    const [userId, setUserId] = useState("");
    const [error, setError] = useState("");
    const chats = useSelector(getChats);
    const dashboardChatData = useSelector(getDashboardChatData);
    const dispatch = useDispatch();

    async function startChat(e) {
        e.preventDefault();
        try {
            let userIdTrimmed = userId.trim();
            let chatId = await dispatch(createChat(userIdTrimmed)).unwrap();
            navigate('/chats/'+ chatId)
        } catch (e) {
            setError(e.message);
            console.error(e);
        }
      }

    return <div className="max-w-screen-lg mx-10 my-10 flex flex-col overflow-y-hidden">
        <div className="font-bold text-3xl mb-4">Start a new chat:</div>

        <input className="min-w-1/4 w-1/4 focus:ring-wedgewood-500 focus:border-wedgewood-500 shadow-sm sm:text-sm border-gray-300 rounded-md px-2 py-2" placeholder="User ID" type="text" value={userId} onChange={event => {
                    setUserId(event.target.value);
                }} />

        <button onClick={startChat} disabled={userId == ""} className="block disabled:opacity-50 text-center min-w-min w-1/4 p-2 border border-wedgewood-600 ring-2 ring-wedgewood-600 font-semibold rounded-full text-white bg-wedgewood-600 select-none focus:outline-none my-4" >
                Start
        </button>
        
        {error && <div className="text-sm font-medium text-alert-red my-1 text-start">{error.toString()}</div>}

        <div className="font-bold text-3xl mb-6">Active chats:</div>
        <div className="h-auto overflow-auto">
            {chats.map(chat => {
                const hasUnreadMessages = checkHasUnreadMessages(chat, dashboardChatData);
                let name = chat.IsAnonymous || chat.UserName == "Anonym" ? chat.UserName + " " +  chat.UserId : chat.UserName;
                return <div key={chat.UserId} className="flex flex-row underline mb-2 items-center">
                        <Link to={'/chats/'+chat.Id} className="mr-2">{name}</Link>
                        {hasUnreadMessages && <div className="h-3 w-3 bg-alert-red rounded-full" />}
                    </div>
            })}
        </div>
    </div>
}

function checkHasUnreadMessages(chat, dashboardChatData) {
    if (chat.LastMessageTimestamp == null) {
        return false;
    }
    const lastReadMessage = dashboardChatData.LastReadMessages.find(m => m.ChatId == chat.Id);
    if (lastReadMessage == null) {
        return true;
    }
    return chat.LastMessageTimestamp > lastReadMessage.LastReadMessageTimestamp;
}

export default Chats
