import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { MultiSelect } from "react-multi-select-component";
import { getMultiUserDocuments, toAlarmModel } from "../../../firebase/firestoreUtils";
import { localizeTimestamp, getReaction } from "../../../utils"
import { DateTimePicker } from "@material-ui/pickers";
import addDays from 'date-fns/addDays'
import startOfToday from 'date-fns/startOfToday';
import { zonedTimeToUtc } from 'date-fns-tz'
import Loader from "../../loader";
import { ArrowRightIcon, ArrowDownIcon } from "@heroicons/react/outline";
import * as userDataUtils from '../../../utils/userDataUtils';

const Alarms = ({ userId, timeZone, schemaVersion }) => {
    const today = startOfToday()

    const [isLoading, setIsLoading] = useState(false);
    const [medications, setMedications] = useState([]);
    const [alarms, setAlarms] = useState([]);
    const [selectedMedications, setSelectedMedications] = useState([]);
    const [startDate, setStartDate] = useState(today);
    const [endDate, setEndDate] = useState(addDays(today, 1));

    useEffect(() => {
        const getAlarms = async () => {
            try {
                setIsLoading(true);
                let query = getMultiUserDocuments("alarms", schemaVersion, userId);
                    
                if (startDate) {
                    const utcDate = zonedTimeToUtc(startDate, "UTC");
                    query = query.where("DateTime", ">=", utcDate);
                }
                if (endDate) {
                    const utcDate = zonedTimeToUtc(endDate, "UTC");
                    query = query.where("DateTime", "<=", utcDate);
                }
                query = query.orderBy("DateTime", "asc");
                const alarms = await query.get();
    
                const data = alarms.docs.map(doc => toAlarmModel(doc));
                const meds = data.reduce((result, current) => {
                    const next = { label: userDataUtils.getAlarmMedicationName(current, userId), value: current.MedicationName }
                    return result.find(m => m.value == next.value) != null ? result : [...result, next]
                }, []);
                setMedications(meds);
                setAlarms(data);
            }
            catch (e) {
                console.log("Alarms loading error");
                console.error(e);
            }
            finally {
                setIsLoading(false);
            }
        };

        getAlarms();
    }, [startDate, endDate]);

    useEffect(() => {
        setSelectedMedications(medications);
    }, [medications]);

    const alarmsToShow = alarms.filter(a => selectedMedications.find(m => m.value == a.MedicationName) != null);

    return <div className="flex flex-col overflow-hidden h-full overflow-y-auto mx-4 px-6 my-2">
        <div className="flex flex-row my-3">
            <div className="mr-3">
                <DateTimePicker
                    autoOk
                    ampm={false}
                    disableFuture
                    value={startDate}
                    onChange={(date)=>{setStartDate(date)}}
                    label="Start"
                    format="d MMM yyyy HH:mm"
                    showTodayButton
                />
            </div>
            
            <div>
                <DateTimePicker
                    autoOk
                    ampm={false}
                    minDate={startDate}
                    value={endDate}
                    onChange={(date)=>{setEndDate(date)}}
                    label="End"
                    format="d MMM yyyy HH:mm"
                    showTodayButton
                />
            </div>
        </div>

        {isLoading && <Loader />}

        <MultiSelect options={medications} 
            value={selectedMedications} 
            hasSelectAll={medications.length > 1}
            onChange={setSelectedMedications} 
            labelledBy="Select Medication" 
            selectAllLabel="All medications" />
        <div className="flex flex-col overflow-y-auto my-2">
            {!isLoading && alarmsToShow.length == 0 && <div className="text-xl my-3">Nothing to show here!</div>}
            {alarmsToShow.map(alarm => {
                return <Alarm key={alarm.Id} alarm={alarm} timeZone={timeZone} userId={userId} />
            })}
        </div>
    </div> 
}

const Alarm = ({ alarm, timeZone, userId }) => {
    const [usersShown, setUsersShown] = useState(false);

    const hasUsers = alarm.Users && alarm.WriterUserIds && alarm.ReaderUserIds;
    const isFollowing = hasUsers && !alarm.WriterUserIds.includes(userId) && alarm.ReaderUserIds.includes(userId);
    const isShared = hasUsers && alarm.WriterUserIds.length > 1 && alarm.WriterUserIds.includes(userId);

    return <div key={alarm.Id} className="my-2">
        <div className="flex flex-row items-center">
            <div className={"text-xl font-semibold mr-2 my-2 text-wedgewood-600"}>{userDataUtils.getAlarmMedicationName(alarm, userId)}</div>
            {isShared && <div className="mr-2 text-xl font-semibold text-share-green">(Shared)</div>}
            {isFollowing && <div className="mr-2 text-xl font-semibold text-follow-pink">(Following)</div>}
        </div>

        <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">Id: </div>    
            <div className="">{alarm.Id}</div>
        </div>

        {/* users expander */}
        {hasUsers && <div className="flex flex-row items-center" onClick={() => setUsersShown(!usersShown)}>
            <div className="text-sm font-semibold mr-1">{`Users`}</div>
            {usersShown ? <ArrowDownIcon className="h-6 w-6" aria-hidden="true"/> : <ArrowRightIcon className="h-6 w-6" aria-hidden="true"/>}
        </div>}

        {usersShown && <div className="flex flex-col mx-5 my-2">
            {Object.keys(alarm.Users).map(userKey => {
                const name = alarm.Users[userKey].Name;
                const hasWriteAccess = alarm.WriterUserIds.includes(userKey)
                const hasReadAccess = alarm.ReaderUserIds.includes(userKey)

                return (<div key={userKey} className="flex flex-col">
                    <div className="flex flex-row items-center">
                        <div className="text-sm font-semibold mr-2">User Id: </div>    
                        <div className="">{userKey}</div>
                    </div>
                    <div className="flex flex-row items-center">
                        <div className="text-sm font-semibold mr-2">Name: </div>    
                        <div className="">{name}</div>
                    </div>
                    <div className="flex flex-row items-center">
                        <div className="text-sm font-semibold mr-2">Has Write Access: </div>    
                        <div className="">{hasWriteAccess ? "Yes" : "No"}</div>
                    </div>
                    <div className="flex flex-row items-center">
                        <div className="text-sm font-semibold mr-2">Has Read Access: </div>    
                        <div className="">{hasReadAccess ? "Yes" : "No"}</div>
                    </div>
                    <div className="h-px bg-wedgewood-500 opacity-50"/>
                </div>)
            })}
        </div>}

        <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">Date: </div>    
            <div className="">{localizeTimestamp(alarm.DateTime, alarm.MedicationTimeZone ?? timeZone)}</div>
        </div>
        
        <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">Time Zone: </div>    
            <div className="">{alarm.MedicationTimeZone ?? timeZone}</div>
        </div>
        <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">Quantity: </div>    
            <div className="">{alarm.Quantity}</div>
        </div>
        <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">Critical: </div>    
            <div className="">{alarm.IsCritical ? "Yes" : "No"}</div>
        </div>

        <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">As needed medication: </div>    
            <div className="">{alarm.AsNeededMedication != null && alarm.AsNeededMedication ? "Yes" : "No"}</div>
        </div>

        <div className="flex flex-row items-center">
                <div className="text-sm font-semibold mr-2">Reaction: </div>    
                <div className="">{getReaction(alarm.Reaction?.Type)}</div>
            </div>

        {alarm.Reaction &&<div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">Reaction at: </div>    
            <div className="">{alarm.Reaction?.DateTime ? localizeTimestamp(alarm.Reaction.DateTime, alarm.MedicationTimeZone ?? timeZone) : "Empty"}</div>
        </div>}

        {alarm.Reaction && <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">User who reacted: </div>    
            <div className="">{alarm.Reaction.UserName}</div>
        </div>}
    </div>
}

Alarm.propTypes = {
    userId: PropTypes.string.isRequired,
    alarm: PropTypes.object.isRequired,
    timeZone: PropTypes.string
}

Alarms.propTypes = {
    userId: PropTypes.string.isRequired,
    timeZone: PropTypes.string,
    schemaVersion: PropTypes.number.isRequired
}

export default Alarms;