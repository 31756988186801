export function getAlarmMedicationName(alarm, userId) {
    return getLocalizedMedicationName(alarm.MedicationName, alarm.ReceiverName, !isOwner(alarm, userId) ? getSingleOwnerName(alarm) : null);
}

export function getMedicationName(medication, userId) {
    return getLocalizedMedicationName(medication.Name, medication.ReceiverName, !isOwner(medication, userId) ? getSingleOwnerName(medication) : null);
}

export function getLocalizedMedicationName(medicationName, receiverName, ownerName) {
    const receiver = receiverName ?? ownerName;
    if (!receiver) {
        return medicationName;
    }
    if (receiver.charAt(receiver.length - 1) == "s") {
        return `${receiver} ${medicationName}`;
    }
    return `${receiver}'s ${medicationName}`;
}

export function isOwner(multiUserEntity, currentUserId) {
    return !multiUserEntity.WriterUserIds || multiUserEntity.WriterUserIds.includes(currentUserId);
}

export function getSingleOwnerName(multiUserEntity) {
    const ownerNames = getOwnerNames(multiUserEntity);
    if (ownerNames.length != 1) {
        return null;
    }
    return ownerNames[0];
}

export function getOwnerNames(multiUserEntity) {
    return multiUserEntity.WriterUserIds.map(w => multiUserEntity.Users[w].Name);
}
