import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getMultiUserDocuments, toAlarmModel } from "../../../firebase/firestoreUtils";
import { localizeTimestamp, getReaction } from "../../../utils";

const fieldsClassNames = {
  "Push Notifications Token": "cursor-pointer"
};

const Profile = ({ profile, activeApp }) => {
  const [alarm, setAlarm] = useState(null);

  useEffect(() => {
      setAlarm(null);
      const fetchData = async () => {
        try {
          const reactionFieldName = profile.schemaVersion >= 8 ? "Reaction.DateTime" : "ReactionDateTime"
          const lastReactedAlarm = await getMultiUserDocuments("alarms", profile.schemaVersion, profile.id)
            .where(reactionFieldName, "!=", null)
            .orderBy(reactionFieldName, "desc")
            .limit(1)
            .get();
          if (lastReactedAlarm.docs.length > 0) {
            setAlarm(toAlarmModel(lastReactedAlarm.docs[0]));
          }
        }
        catch(e) {
          console.log("Load user profile data error");
          console.error(e);
        }
      }
      fetchData();
    }, [profile])
    const app = activeApp;
    const timeZone = activeApp?.SmartDevice.TimeZone;

    let fields = { ...profile.fields };
    if (app != null && app.MyHeartBeat != null) {
      fields["Last Active App Id"] = app.Id;
      if (app.MyHeartBeat != null) {
        fields["Last Heart Beat"] = localizeTimestamp(app.MyHeartBeat, timeZone);
      }
      fields["Bluetooth Permissions State"] = app.BluetoothPermissionState ?? "Unknown";
      fields["Location Permissions State"] = app.LocationPermissionState ?? "Unknown";
      fields["Notifications Permissions State"] = app.NotificationSettings?.AuthorizationStatus ?? "Unknown";
      if (app.PushNotificationsToken) {
        fields["Push Notifications Token"] = `${app.PushNotificationsToken.substring(0, 10)}... (Click to copy)`;
      }
    }
    if (alarm) {
      const medicationName = alarm.MedicationName ?? "";
      fields["Last Reaction"] = medicationName + " " + getReaction(alarm.Reaction.Type) + " at " + localizeTimestamp(alarm.Reaction.DateTime, timeZone);
    }

    const onFieldClick = async (field) => {
      try {
        if (field == "Push Notifications Token") {
          await navigator.clipboard.writeText(app.PushNotificationsToken);
          console.log("Push notification token copied to the clipboard.")
        }
      }
      catch(e) {
        console.log("Click on field error");
        console.error(e);
      }
    }

    return <>
    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 mt-6 max-w-5xl mx-4 px-6">
        {Object.keys(fields).map(field => (
          <div key={field} className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">{field}</dt>
            <dd className={"mt-1 text-sm text-gray-900 " + fieldsClassNames[field] ?? ""} onClick={() => onFieldClick(field)}>
              {fields[field]}
            </dd>
          </div>
        ))}
      </dl>
    </>
}

Profile.propTypes = {
    profile: PropTypes.shape({
        fields: PropTypes.object.isRequired,
        about: PropTypes.string,
        id: PropTypes.string.isRequired,
        schemaVersion: PropTypes.number.isRequired,
    }),
    activeApp: PropTypes.object
}

export default Profile;
