import React, { useState, useEffect } from "react";
import firebase, { auth } from "../firebase";
import { getCollection } from "../firebase/firestoreUtils"
import ChatComponent from "../components/chat";
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';
import { getUserSettings } from "../store/userDataSlice";
import { getDashboardChatData, getChatById } from "../store/chatsSlice";

const Chat = (props) => {
    const [messages, setMessages] = useState([]);
    const chat = useSelector(state => getChatById(state, props.id));
    const userSettings = useSelector(getUserSettings);
    const dashboardChatData = useSelector(getDashboardChatData);

    useEffect(() => {
        if (!dashboardChatData) {
            return;
        }

        return getCollection("chats").doc(props.id).collection("chat_messages")
            .orderBy("SavedAt", "asc")
            .limitToLast(30)
            .onSnapshot({ includeMetadataChanges: false }, async (querySnapshot) => {
                var messages = [];
                querySnapshot.forEach((doc) => {
                    const data = doc.data()
                    messages.push({ id: doc.id, createdAt: data.CreatedAt, savedAt: data.SavedAt, message: data.Message, userFirstName: data.UserFirstName, userAvatarUrl: data.UserAvatarUrl, userId: data.UserId });
                });
                messages.sort((a, b) => {
                    if (a.createdAt > b.createdAt) return 1;
                    if (a.createdAt < b.createdAt) return -1;
                    return 0;
                });

                setMessages(messages)
                let lastMessage = messages[messages.length - 1]
                if (lastMessage != null) {
                    const existedLastReadMessage = dashboardChatData.LastReadMessages.find(m => m.ChatId == props.id);
                    if (existedLastReadMessage == null || existedLastReadMessage.LastReadMessageTimestamp < lastMessage.savedAt) {
                        const dashboardChatDataRef = getCollection("dashboard_chat_data").doc(dashboardChatData.Id);
                        const nextData = [ ...dashboardChatData.LastReadMessages.filter(m => m.ChatId != props.id), { ChatId: props.id, LastReadMessageTimestamp: lastMessage.savedAt } ];
                        await dashboardChatDataRef.set({ LastReadMessages: nextData }, { merge: true });
                    }
                }
                
            }, (error) => {
                console.log("Loading chat messages error!")
                console.error(error);
            });
    }, [dashboardChatData != null])

    async function onSubmit(message) {
        try {
            const userSettingsName = userSettings != null && userSettings.FirstName != null ? userSettings.FirstName + " " + userSettings.LastName : null
            const displayName = userSettingsName ?? auth.currentUser.displayName
            const collection = getCollection("chats").doc(props.id).collection("chat_messages")
            await collection.add({
                "CreatedAt": firebase.firestore.Timestamp.now(),
                "SavedAt": firebase.firestore.FieldValue.serverTimestamp(),
                "Message": message.text,
                "UserFirstName": displayName,
                "UserId": auth.currentUser.uid,
                "UserAvatarUrl": auth.currentUser.photoURL});
                
            console.log("Chat message saved")
        }
        catch (error) {
            console.error(error);
        }        
    }
    return <ChatComponent messages={messages} onSubmit={onSubmit} chatUserId={chat?.UserId} userName={chat?.UserName} className={props.className}/>
}

Chat.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string
}

export default Chat;