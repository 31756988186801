import React, { useState } from "react";
import PropTypes from "prop-types";
import { ArrowRightIcon, ArrowDownIcon } from "@heroicons/react/outline";
import parse from "date-fns/parse";
import format from 'date-fns/format';
import orderBy from 'lodash/orderBy';
import * as userDataUtils from '../../../utils/userDataUtils';

const Medication = ({ userId, medication, isActive }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [entriesShown, setEntriesShown] = useState(false);
    const [usersShown, setUsersShown] = useState(false);
    const [placesShown, setPlacesShown] = useState(false);
    const [devicesShown, setDevicesShown] = useState(false);
    const { Pattern, PlacesData, DoseDevicesData } = medication;
    const name = userDataUtils.getMedicationName(medication, userId);
    const entries = Pattern != null ? orderBy(Pattern.Entries, ['DayNumber', 'Time'], ['asc', 'asc']) : [];
    const endDate = Pattern != null ? Pattern.EndDate && Pattern.EndDate != "" ? Pattern.EndDate : "Never" : "Unknown";
    const patternMode = Pattern != null ? getMode(Pattern.Length)+ " (" + Pattern.Length + ")" : "Unknown";
    const nameColor = !isActive ? " text-gray-400" : " text-wedgewood-600";
    const asNeeded = medication.AsNeeded != null && medication.AsNeeded;
    const hasUsers = medication.Users && medication.WriterUserIds && medication.ReaderUserIds;
    const isFollowing = hasUsers && !medication.WriterUserIds.includes(userId) && medication.ReaderUserIds.includes(userId);
    const isShared = hasUsers && medication.WriterUserIds.length > 1 && medication.WriterUserIds.includes(userId);
    const isOldData = medication.SchemaVersion < 5

    if (isOldData) {
        return <div className="flex flex-col my-1">
            <div className="flex flex-row items-center">
                <div className={"text-xl font-semibold mr-2 my-2" + nameColor}>{name}</div>
                <div className="mr-2 text-xl font-semibold text-red-800">(old data)</div>
            </div>
        </div>
    }

    if (!isExpanded) {
        return <div className="flex flex-col my-1">
            <div className="flex flex-row items-center" onClick={() => setIsExpanded(!isExpanded)}>
                <div className={"text-xl font-semibold mr-2 my-2" + nameColor}>{name + (!isActive ? " (Inactive)" : "")}</div>
                {isShared && <div className="mr-2 text-xl font-semibold text-share-green">(Shared)</div>}
                {isFollowing && <div className="mr-2 text-xl font-semibold text-follow-pink">(Following)</div>}
                {isExpanded ? <ArrowDownIcon className="ml-2 h-6 w-6" aria-hidden="true"/> : <ArrowRightIcon className="h-6 w-6" aria-hidden="true"/>}
            </div>
        </div>
    }

    return <div className="flex flex-col my-1">
        <div className="flex flex-row items-center" onClick={() => setIsExpanded(!isExpanded)}>
                <div className={"text-xl font-semibold mr-2 my-2" + nameColor}>{name + (!isActive ? " (Inactive)" : "")}</div>
                {isShared && <div className="mr-2 text-xl font-semibold text-share-green">(Shared)</div>}
                {isFollowing && <div className="mr-2 text-xl font-semibold text-follow-pink">(Following)</div>}
                {isExpanded ? <ArrowDownIcon className="ml-2 h-6 w-6" aria-hidden="true"/> : <ArrowRightIcon className="h-6 w-6" aria-hidden="true"/>}
        </div>

        <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">Id: </div>    
            <div className="">{medication.Id}</div>
        </div>

        {/* users expander */}
        {hasUsers && <div className="flex flex-row items-center" onClick={() => setUsersShown(!usersShown)}>
            <div className="text-sm font-semibold mr-1">{`Users`}</div>
            {usersShown ? <ArrowDownIcon className="h-6 w-6" aria-hidden="true"/> : <ArrowRightIcon className="h-6 w-6" aria-hidden="true"/>}
        </div>}

        {usersShown && <div className="flex flex-col mx-5 my-2">
            {Object.keys(medication.Users).map(userKey => {
                const name = medication.Users[userKey].Name;
                const hasWriteAccess = medication.WriterUserIds.includes(userKey)
                const hasReadAccess = medication.ReaderUserIds.includes(userKey)

                return (<div key={userKey} className="flex flex-col">
                    <div className="flex flex-row items-center">
                        <div className="text-sm font-semibold mr-2">User Id: </div>    
                        <div className="">{userKey}</div>
                    </div>
                    <div className="flex flex-row items-center">
                        <div className="text-sm font-semibold mr-2">Name: </div>    
                        <div className="">{name}</div>
                    </div>
                    <div className="flex flex-row items-center">
                        <div className="text-sm font-semibold mr-2">Has Write Access: </div>    
                        <div className="">{hasWriteAccess ? "Yes" : "No"}</div>
                    </div>
                    <div className="flex flex-row items-center">
                        <div className="text-sm font-semibold mr-2">Has Read Access: </div>    
                        <div className="">{hasReadAccess ? "Yes" : "No"}</div>
                    </div>
                    <div className="h-px bg-wedgewood-500 opacity-50"/>
                </div>)
            })}
        </div>}

        {<div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">Time Zone: </div>    
            <div className="">{medication.TimeZone ?? "Empty"}</div>
        </div>}

        {<div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">Receiver name: </div>    
            <div className="">{medication.ReceiverName ?? "Empty"}</div>
        </div>}

        {medication.StandardMedicationId && <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">Standard medication: </div>    
            <div className="mr-2">{medication.StandardMedicationId}</div>
            <div className="">{medication.StandardMedicationName ?? ""}</div>
        </div>}

        {!asNeeded && <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">Always with me: </div>    
            <div className="">{medication.IsAlwaysWithMe ? "Yes" : "No"}</div>
        </div>}

        {!asNeeded && <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">Critical: </div>    
            <div className="">{medication.IsCritical ? "Yes" : "No"}</div>
        </div>}
        
        {!asNeeded && <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">Start date: </div>    
            <div className="">{medication.Pattern.StartDate + " (" + getStartDateWeekday(Pattern.StartDate) + ")"}</div>
        </div>}
        {!asNeeded && <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">End date: </div>    
            <div className="">{endDate}</div>
        </div>}

        {!asNeeded && <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">Pattern: </div>    
            <div className="">{patternMode}</div>
        </div>}

        {!asNeeded && <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">Continuous notifications enabled: </div>    
            <div className="">{medication.ContinuousNotificationsEnabled == null || medication.ContinuousNotificationsEnabled == true ? "Yes" : "No"}</div>
        </div>}

        <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">Quantity left: </div>    
            <div className="">{medication.QuantityLeft != null ? medication.QuantityLeft : "Not set"}</div>
        </div>

        {medication.RefillAmount && <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">Saved refill amount: </div>    
            <div className="">{medication.RefillAmount}</div>
        </div>}

        <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">As needed: </div>    
            <div className="">{medication.AsNeeded != null && medication.AsNeeded ? "Yes" : "No"}</div>
        </div>

        {/* places expander */}
        {!asNeeded && PlacesData.length > 0 && <div className="flex flex-row items-center" onClick={() => setPlacesShown(!placesShown)}>
            <div className="text-sm font-semibold mr-1">Places: </div>    
            {placesShown ? <ArrowDownIcon className="h-6 w-6" aria-hidden="true"/> : <ArrowRightIcon className="h-6 w-6" aria-hidden="true"/>}
        </div>}

        {!asNeeded && PlacesData.length > 0 && placesShown && <div className="flex flex-col mx-5 my-2">
            {PlacesData.map(place => {
                return (<div key={place.Id} className="flex flex-col">
                    <div className="flex flex-row items-center">
                        <div className="text-sm font-semibold mr-2">Id: </div>    
                        <div className="">{place.Id}</div>
                    </div>

                    <div className="flex flex-row items-center">
                        <div className="text-sm font-semibold mr-2">Name: </div>    
                        <div className="">{place.Name}</div>
                    </div>

                    {PlacesData.indexOf(place) != PlacesData.length - 1 && <div className="h-px bg-wedgewood-500 opacity-50"/>}
                </div>)
            })}
        </div>}

        {/* devices expander */}
        {!asNeeded && DoseDevicesData.length > 0 && <div className="flex flex-row items-center" onClick={() => setDevicesShown(!devicesShown)}>
            <div className="text-sm font-semibold mr-1">Dose devices: </div>    
            {devicesShown ? <ArrowDownIcon className="h-6 w-6" aria-hidden="true"/> : <ArrowRightIcon className="h-6 w-6" aria-hidden="true"/>}
        </div>}

        {!asNeeded && DoseDevicesData.length > 0 && devicesShown && <div className="flex flex-col mx-5 my-2">
            {DoseDevicesData.map(device => {
                return (<div key={device.Id} className="flex flex-col">
                    <div className="flex flex-row items-center">
                        <div className="text-sm font-semibold mr-2">Id: </div>    
                        <div className="">{device.Id}</div>
                    </div>

                    <div className="flex flex-row items-center">
                        <div className="text-sm font-semibold mr-2">Serial number: </div>    
                        <div className="">{device.SerialNumber}</div>
                    </div>

                    {DoseDevicesData.indexOf(device) != DoseDevicesData.length - 1 && <div className="h-px bg-wedgewood-500 opacity-50"/>}

                </div>)
            })}
        </div>}

        {/* entries expander */}
        {!asNeeded && <div className="flex flex-row items-center" onClick={() => setEntriesShown(!entriesShown)}>
            <div className="text-sm font-semibold mr-1">{`Entries (${entries.length}):`}</div>
            {entriesShown ? <ArrowDownIcon className="h-6 w-6" aria-hidden="true"/> : <ArrowRightIcon className="h-6 w-6" aria-hidden="true"/>}
        </div>}

        {!asNeeded && entriesShown && <div className="flex flex-col mx-5 my-2">
            {entries.map(entry => {
                return (<div key={getEntryId(entry.DayNumber, entry.Time)} className="flex flex-col">
                    <div className="flex flex-row items-center">
                        <div className="text-sm font-semibold mr-2">Day number: </div>    
                        <div className="">{entry.DayNumber}</div>
                    </div>
                    <div className="flex flex-row items-center">
                        <div className="text-sm font-semibold mr-2">Quantity: </div>    
                        <div className="">{entry.Quantity}</div>
                    </div>
                    <div className="flex flex-row items-center">
                        <div className="text-sm font-semibold mr-2">Time: </div>    
                        <div className="">{getTimeString(entry.Time)}</div>
                    </div>

                    {entry.WeekendTime && <div className="flex flex-row items-center">
                        <div className="text-sm font-semibold mr-2">Weekend time: </div>    
                        <div className="">{getTimeString(entry.WeekendTime)}</div>
                    </div>}

                    {entries.indexOf(entry) != entries.length - 1 && <div className="h-px bg-wedgewood-500 opacity-50"/>}

                </div>)
            })}
        </div>}
        
    </div>
}

function getStartDateWeekday(startDateString) {
    const date = parse(startDateString, 'yyyy-MM-dd', new Date())
    return format(date, "EEEE")
}

function getEntryId(dayNumber, time) {
    return dayNumber + "_" + getTimeString(time);
}

function getTimeString(time) {
    const date = time.toDate();
    const hours = date.getUTCHours().toString();
    const minutes = date.getUTCMinutes().toString();
    return (hours.length == 1 ? "0" + hours : hours) + ":" + (minutes.length == 1 ? "0" + minutes : minutes);
}

function getMode(patternLength) {
    const remainder = patternLength % 7;
    if (remainder != 0) {
        return "Every " + remainder + " day";
    }
    const result = patternLength / 7;
    return "Every " + result + " week";
}

Medication.propTypes = {
    userId: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    medication: PropTypes.shape({
        Id: PropTypes.string.isRequired,
        Name: PropTypes.string.isRequired,
        SchemaVersion: PropTypes.number.isRequired,
        ReceiverName: PropTypes.string,
        IsCritical: PropTypes.bool.isRequired,
        IsAlwaysWithMe: PropTypes.bool.isRequired,
        ContinuousNotificationsEnabled: PropTypes.bool,
        QuantityLeft: PropTypes.number,
        RefillAmount: PropTypes.number,
        AsNeeded: PropTypes.bool,
        StandardMedicationId: PropTypes.number,
        StandardMedicationName: PropTypes.string,
        WriterUserIds: PropTypes.array,
        ReaderUserIds: PropTypes.array,
        Users: PropTypes.object,
        TimeZone: PropTypes.string,
        Pattern: PropTypes.shape({
            StartDate: PropTypes.string.isRequired,
            EndDate: PropTypes.string,
            Length: PropTypes.number.isRequired,
            Entries: PropTypes.arrayOf(PropTypes.shape({
                DayNumber: PropTypes.number.isRequired,
                Time: PropTypes.object.isRequired,
                WeekendTime: PropTypes.object,
                Quantity: PropTypes.number.isRequired,
            })).isRequired
        }).isRequired,
        PlacesData: PropTypes.arrayOf(PropTypes.shape({
            Id: PropTypes.string.isRequired,
            Name: PropTypes.string.isRequired
        })).isRequired,
        DoseDevicesData: PropTypes.arrayOf(PropTypes.shape({
            Id: PropTypes.string.isRequired,
            SerialNumber: PropTypes.string.isRequired
        })).isRequired,        
    }).isRequired
}

export default Medication;