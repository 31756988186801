import React from 'react';
import { formatRelative } from 'date-fns';
import PropTypes from 'prop-types';

const formatDate = date => {
  let formattedDate = '';
  if (date) {
    formattedDate = formatRelative(date, new Date());
    formattedDate = formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
  }
  return formattedDate;
};

const Message = ({
  createdAt = null,
  message = '',
  userFirstName = '',
  userAvatarUrl = '',
  alignment,
}) => {
  if (!Message) return null;
  const messageClassName = "text-base text-tertiary " + alignment

  return (
    <div className="px-4 py-4 rounded-md overflow-hidden flex items-start">
      {userAvatarUrl && <img src={userAvatarUrl} className="rounded-full mr-4" width={45} height={45} />}
      <div>
        <div className="flex items-center mb-1">
          {userFirstName && <p className="mr-1 text-tertiary text-sm">{userFirstName}</p>}
          {createdAt?.seconds && <span className="text-tertiary text-xs">{formatDate(new Date(createdAt.seconds * 1000))}</span>}
        </div>
        <p className={messageClassName}>{message}</p>
      </div>
    </div>
  );
};

Message.propTypes = {
  createdAt: PropTypes.object,
  message: PropTypes.string,
  userFirstName: PropTypes.string,
  userAvatarUrl: PropTypes.string,
  alignment: PropTypes.string
}

export default Message;