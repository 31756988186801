import { format, utcToZonedTime } from 'date-fns-tz'

export function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export function distinct(arr) {
    return arr.reduce((x, y) => x.includes(y) ? x : [...x, y], []);
}

export function downloadFile(url, name) {
    if (typeof window !== "undefined") {
        var link = document.createElement("a");
        link.download = name;
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}

export function localizeTimestamp(timestamp, timeZone = null) {
    let originalDate = timestamp.toDate();
    if (timeZone != null) {
        const zonedDate = utcToZonedTime(originalDate, timeZone)
        return format(zonedDate, "yyyy-MM-dd HH:mmXXX", { timeZone: timeZone });
    } else {
        const utcDate = utcToZonedTime(originalDate, "UTC")
        return format(utcDate, "yyyy-MM-dd HH:mm", { timeZone: "UTC" }) + " UTC";
    }
}

export function getReaction(reactionType) {
    switch (reactionType) {
        case "MedicineTakenButtonPressedInApp":
            return "Taken (In App)";
        case "MedicineTakenButtonPressedInMedbox":
            return "Taken (In Medbox)";
        case "MedicineTakenButtonPressedInNotification":
            return "Taken (In Notification)";
        case "MedicineSkippedButtonPressedInApp":
            return "Skipped";
        default:
            return "Missed"
    }
}

export function capitalize(value) {
    return value.charAt(0).toUpperCase() + value.slice(1);
}