import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getCollection } from "../../../firebase/firestoreUtils";
import Loader from "../../loader";
import { ArrowRightIcon, ArrowDownIcon } from "@heroicons/react/outline";
import { localizeTimestamp } from "../../../utils";

const Places = ({ userId, timeZone }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [places, setPlaces] = useState([]);
    const [devices, setDevices] = useState([]);

    useEffect(() => {
        async function loadData() {
            try {
                setIsLoading(true);
                const placesDocs = await getCollection("places").where("UserId", "==", userId).get();
                const placesSettingsDocs = await getCollection("place_settings").where("UserId", "==", userId).get();
                const placesSettingsData = placesSettingsDocs.docs.map(doc => { return { Id: doc.id, ...doc.data() }});
                let placesData = placesDocs.docs.map(doc => { return { Id: doc.id, ...doc.data(), settings: placesSettingsData.find(s => s.PlaceId == doc.id) }});


                const devicesDocs = await getCollection("dose_devices").where("UserId", "==", userId).get();
                const devicesSettingsDocs = await getCollection("dose_device_settings").where("UserId", "==", userId).get();
                const devicesSettingsData = devicesSettingsDocs.docs.map(doc => { return { Id: doc.id, ...doc.data() }});
                const devicesData = devicesDocs.docs.map(doc => { return { Id: doc.id, ...doc.data(), settings: devicesSettingsData.find(s => s.DoseDeviceId == doc.id) }});

                setPlaces(placesData);
                setDevices(devicesData);
            }
            catch (e) {
                console.log("Places and devices loading error");
                console.error(e);
            }
            finally {
                setIsLoading(false);
            }
        }

        loadData();
    }, []);
    
    return <div className="h-full flex-grow-0 overflow-y-auto mx-4 px-6">
        {isLoading && <Loader />}
        <div className="text-xl my-3 font-bold">Places:</div>
        {!isLoading && places.length == 0 && <div className="text-lg my-3 text-gray-500">Nothing to show here!</div>}
        {places.map(place => {
            return <Place key={place.Id} place={place}/>
        })}

        <div className="text-xl my-3 font-bold">Dose devices:</div>
        {!isLoading && devices.length == 0 && <div className="text-lg my-3 text-gray-500">Nothing to show here!</div>}
        {devices.map(device => {
            return <Device key={device.Id} device={device} timeZone={timeZone} />
        })}
    </div>
}

const Place = ({ place }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const { settings } = place;

    if (!isExpanded) {
        return <div className="flex flex-col my-1">
            <div className="flex flex-row items-center" onClick={() => setIsExpanded(!isExpanded)}>
                <div className={"text-xl font-semibold mr-2 my-2 text-wedgewood-600"}>{place.Name}</div>
                {isExpanded ? <ArrowDownIcon className="h-6 w-6" aria-hidden="true"/> : <ArrowRightIcon className="h-6 w-6" aria-hidden="true"/>}
            </div>
        </div>
    }

    return (<div className="flex flex-col my-1">
        <div className="flex flex-row items-center" onClick={() => setIsExpanded(!isExpanded)}>
                <div className={"text-xl font-semibold mr-2 my-2 text-wedgewood-600"}>{place.Name}</div>
                {isExpanded ? <ArrowDownIcon className="h-6 w-6" aria-hidden="true"/> : <ArrowRightIcon className="h-6 w-6" aria-hidden="true"/>}
        </div>

        <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">Id: </div>    
            <div className="">{place.Id}</div>
        </div>

        <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">Schema Version: </div>    
            <div className="">{place.SchemaVersion}</div>
        </div>

        <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">Address: </div>    
            <div className="mr-2">{place.Country}</div>
            <div className="mr-2">{place.Address}</div>
        </div>

        <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">Location: </div>    
            <div className="mr-2">latitude</div>
            <div className="mr-2">{place.Location.latitude}</div>
            <div className="mr-2">longitude</div>
            <div className="mr-2">{place.Location.longitude}</div>
        </div>

        {settings && <SettingsFragment settings={settings}/>}

    </div>)
}

const Device = ({ device, timeZone }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const { settings } = device;

    if (!isExpanded) {
        return <div className="flex flex-col my-1">
            <div className="flex flex-row items-center" onClick={() => setIsExpanded(!isExpanded)}>
                <div className={"text-xl font-semibold mr-2 my-2 text-wedgewood-600"}>{device.Name ?? device.SerialNumber}</div>
                {isExpanded ? <ArrowDownIcon className="h-6 w-6" aria-hidden="true"/> : <ArrowRightIcon className="h-6 w-6" aria-hidden="true"/>}
            </div>
        </div>
    }

    return (<div className="flex flex-col my-1">
        <div className="flex flex-row items-center" onClick={() => setIsExpanded(!isExpanded)}>
                <div className={"text-xl font-semibold mr-2 my-2 text-wedgewood-600"}>{device.Name ?? device.SerialNumber}</div>
                {isExpanded ? <ArrowDownIcon className="h-6 w-6" aria-hidden="true"/> : <ArrowRightIcon className="h-6 w-6" aria-hidden="true"/>}
        </div>

        <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">Id: </div>    
            <div className="">{device.Id}</div>
        </div>

        <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">Serial Number: </div>    
            <div className="">{device.SerialNumber}</div>
        </div>

        <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">Firmware Api Version: </div>    
            <div className="">{device.FirmwareApiVersion ?? "Unknown"}</div>
        </div>

        <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">Firmware Version: </div>    
            <div className="">{device.FirmwareVersion ?? "Unknown"}</div>
        </div>

        <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">Model Name: </div>    
            <div className="">{device.ModelName ?? "Unknown"}</div>
        </div>

        <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">Model Variant: </div>    
            <div className="">{device.ModelVariant ?? "Unknown"}</div>
        </div>

        <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">Hardware Version: </div>    
            <div className="">{device.HardwareVersion ?? "Unknown"}</div>
        </div>

        {device.Location && <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">Location: </div>    
            <div className="mr-2">latitude</div>
            <div className="mr-2">{device.Location.latitude}</div>
            <div className="mr-2">longitude</div>
            <div className="mr-2">{device.Location.longitude}</div>
        </div>}

        <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">Last Connection Time: </div>    
            <div className="">{device.ConnectedAt != null ? localizeTimestamp(device.ConnectedAt, timeZone) : "Unknown"}</div>
        </div>

        <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">Disconnected: </div>    
            <div className="">{device.DisconnectedAt != null ? localizeTimestamp(device.DisconnectedAt, timeZone) : "Unknown"}</div>
        </div>

        <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">Claim Status: </div>    
            <div className="">{device.ClaimStatus != null ? device.ClaimStatus : "Unknown"}</div>
        </div>

        <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">Last Command Id: </div>    
            <div className="">{device.CommandId != null ? device.CommandId : "Unknown"}</div>
        </div>

        {settings && <SettingsFragment settings={settings}/>}
    </div>)
}

const SettingsFragment = ({ settings }) => {
    return <>
        {settings.Name && <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">Name: </div>    
            <div className="">{settings.Name}</div>
        </div>}
        <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">Bring: </div>    
            <div className="">{settings.IsBringOn ? "On" : "Off"}</div>
        </div>
        <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">Bring Sound: </div>    
            <div className="">{settings.BringSound}</div>
        </div>
        <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">Bring Strategy: </div>    
            <div className="">{settings.BringStrategy}</div>
        </div>
        <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">Settings Schema Version: </div>    
            <div className="">{settings.SchemaVersion}</div>
        </div>
    </>
}

SettingsFragment.propTypes = {
    settings: PropTypes.object.isRequired
}

Device.propTypes = {
    device: PropTypes.object.isRequired,
    timeZone: PropTypes.string
}

Place.propTypes = {
    place: PropTypes.object.isRequired
}

Places.propTypes = {
    userId: PropTypes.string.isRequired,
    timeZone: PropTypes.string
}

export default Places;