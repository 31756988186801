import React, { useEffect, useState } from "react";
import { UserGroupIcon, ChatIcon } from "@heroicons/react/outline"
import { useDispatch, useSelector } from "react-redux";
import { MANAGE_USERS } from "../permissionConstants";
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from "../firebase";
import { getIsUserDataInitialized, getPermissions, getRole, getUserSettings, logout } from "../store/userDataSlice";
import Shell from "./appShell/shell";
import PropTypes from "prop-types";
import { getChats, getDashboardChatData } from "../store/chatsSlice";

const AppShell = (props) => {
  const dispatch = useDispatch();
  const isUserDataInitialized = useSelector(getIsUserDataInitialized);
  const permissions = useSelector(getPermissions);
  const userSettings = useSelector(getUserSettings);
  const role = useSelector(getRole);
  const [ firebaseUser ] = useAuthState(auth);
  const [ tabs, setTabs ] = useState([]);
  const name = isUserDataInitialized && userSettings != null ? userSettings.FirstName + " " + userSettings.LastName : firebaseUser?.displayName;
  const user = { name: name, role: role, imageUrl: firebaseUser?.photoURL };
  const chats = useSelector(getChats);
  const dashboardChatData = useSelector(getDashboardChatData);

  useEffect(() => {
    if (!isUserDataInitialized) {
        return;
    }
    
    let tabs = [];
    if (permissions.indexOf(MANAGE_USERS) !== -1) {
        const chatsHaveBagde = chats.find(chat => {
          if (chat.LastMessageTimestamp == null) {
            return false;
          }
          if (dashboardChatData == null) {
            // dasboard chat data is loading
            return false;
          }

          const lastReadMessage = dashboardChatData.LastReadMessages.find((m) => m.ChatId == chat.Id);
          return lastReadMessage != null 
            ? chat.LastMessageTimestamp > lastReadMessage.LastReadMessageTimestamp
            : true;
        }) != null;

        tabs.push({ name: "Chats", icon: ChatIcon, link: '/chats', hasBadge: chatsHaveBagde });
        tabs.push({ name: "User Management", icon: UserGroupIcon, link: '/users', hasBadge: false });
    }

    setTabs(tabs);
  }, [ permissions, isUserDataInitialized, chats, dashboardChatData ]);
  
  return <Shell tabs={tabs} 
                activeTab={tabs.find(t => props.uri?.includes(t.link))}
                content={props.page} 
                user={user} 
                logout={() => dispatch(logout())}
                {...props} />;
}

AppShell.propTypes = {
  uri: PropTypes.string,
  page: PropTypes.node,
};

export default AppShell;
