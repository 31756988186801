import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import firebase from "../../../firebase";
import { getCollection } from "../../../firebase/firestoreUtils";
import Loader from "../../loader";
import { ArrowRightIcon, ArrowDownIcon } from "@heroicons/react/outline";
import { localizeTimestamp } from "../../../utils";
import orderBy from 'lodash/orderBy';
import chunk from 'lodash/chunk';
import { distinct } from "../../../utils";

const Apps = ({ userId }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [apps, setApps] = useState([]);

    useEffect(() => {
        async function loadData() {
            try {
                setIsLoading(true);
                const apps = await getCollection("apps").where("ActiveUserId", "==", userId).get();
                let appsData = apps.docs.map(doc => { return { Id: doc.id, ...doc.data() }});

                let smartDevicesDocs = []
                const smartDevicesIds = distinct(appsData.map(app => app.SmartDeviceId).filter(id => id != null));
                const smartDevicesIdsChunks = chunk(smartDevicesIds, 10);
                for (const smartDevicesChunk of smartDevicesIdsChunks) {
                    const result = await getCollection("smart_devices").where(firebase.firestore.FieldPath.documentId(), "in", smartDevicesChunk).get();
                    smartDevicesDocs = [ ...smartDevicesDocs, ...result.docs ]
                }
                const smartDevicesData = smartDevicesDocs.map(doc => { return { Id: doc.id, ...doc.data() }});
                appsData = appsData.map(app => { return { ...app, SmartDevice: smartDevicesData.find(s => s.Id == app.SmartDeviceId) }});
                setApps(orderBy(appsData, ({ MyHeartBeat }) => MyHeartBeat || '', ['desc']));
            }
            catch (e) {
                console.log("Apps loading error");
                console.error(e);
            }
            finally {
                setIsLoading(false);
            }
        }

        loadData();
    }, []);
    
    return <div className="h-full flex-grow-0 overflow-y-auto mx-4 px-6">
        {isLoading && <Loader />}
        {!isLoading && apps.length == 0 && <div className="text-xl my-3">Nothing to show here!</div>}
        {apps.length > 0 && <div>
            {apps.map(app => {
                return <App key={app.Id} app={app}/>
            })}
        </div>}
    </div>
}

const App = ({ app }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const copyToken = async () => {
        if (app.PushNotificationsToken == null) {
            return;
        }

        try {
            await navigator.clipboard.writeText(app.PushNotificationsToken);
            console.log("Push notification token copied to the clipboard.")
        }
        catch(e) {
          console.log("Push notification token copy error");
          console.error(e);
        }
    }

    const smartDevice = app.SmartDevice;

    if (!isExpanded) {
        return <div className="flex flex-col my-1">
            <div className="flex flex-row items-center" onClick={() => setIsExpanded(!isExpanded)}>
                <div className={"text-xl font-semibold mr-2 my-2"}>{app.Id}</div>
                {smartDevice && <>
                    <div className="mr-2">{smartDevice.Name}</div>
                    <div className="mr-2 font-bold">{"App v." + app.Version + " " + app.VersionBuild}</div>
                    <div className="mr-2">{"iOS v." + smartDevice.Version}</div>
                </>}
                {isExpanded ? <ArrowDownIcon className="h-6 w-6" aria-hidden="true"/> : <ArrowRightIcon className="h-6 w-6" aria-hidden="true"/>}
            </div>
        </div>
    }

    return (<div className="flex flex-col my-1">
        <div className="flex flex-row items-center" onClick={() => setIsExpanded(!isExpanded)}>
                <div className={"text-xl font-semibold mr-2 my-2"}>{app.Id}</div>
                {smartDevice && <>
                    <div className="mr-2">{smartDevice.Name}</div>
                    <div className="mr-2">{"iOS v." + smartDevice.Version}</div>
                </>}
                {isExpanded ? <ArrowDownIcon className="h-6 w-6" aria-hidden="true"/> : <ArrowRightIcon className="h-6 w-6" aria-hidden="true"/>}
        </div>

        <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">Installed: </div>    
            <div className="">{app.InstallationDateTime != null ? localizeTimestamp(app.InstallationDateTime, smartDevice?.TimeZone) : "Unknown"}</div>
        </div>

        <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">Last Active Time: </div>    
            <div className="">{app.MyHeartBeat != null ? localizeTimestamp(app.MyHeartBeat, smartDevice?.TimeZone) : "Unknown"}</div>
        </div>

        <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">Schema version: </div>    
            <div className="mr-2">{app.SchemaVersion}</div>
        </div>

        <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">App version: </div>    
            <div className="mr-2">{app.Version}</div>
            <div className="">{"Build " + app.VersionBuild}</div>
        </div>

        <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">Push notification token: </div>    
            <div className="cursor-pointer" onClick={copyToken}>{app.PushNotificationsToken != null ? `${app.PushNotificationsToken.substring(0, 10)}... (Click to copy)` : "Unknown"}</div>
        </div>

        <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">Marketing source: </div>    
            <div className="">{app.MarketingSourceId != null ? app.MarketingSourceId : "Unknown"}</div>
        </div>

        <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">Bluetooth Permissions State: </div>    
            <div className="">{app.BluetoothPermissionState ?? "Unknown"}</div>
        </div>

        <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">Location Permissions State: </div>    
            <div className="">{app.LocationPermissionState ?? "Unknown"}</div>
        </div>

        <div className="flex flex-row items-center">
            <div className="text-sm font-semibold mr-2">Notifications Permissions State: </div>    
            <div className="">{app.NotificationSettings?.AuthorizationStatus ?? "Unknown"}</div>
        </div>

        {smartDevice != null && <>
            <div className="flex flex-row items-center">
                <div className="text-sm font-semibold mr-2">Device: </div>    
                <div className="mr-2">{smartDevice.DeviceType}</div>
                <div className="">{smartDevice.Idiom}</div>
            </div>

            <div className="flex flex-row items-center">
                <div className="text-sm font-semibold mr-2">Timezone: </div>    
                <div className="">{smartDevice.TimeZone}</div>
            </div>

            <div className="flex flex-row items-center">
                <div className="text-sm font-semibold mr-2">Locale: </div>    
                <div className="mr-2">{smartDevice.LanguageCode}</div>
                <div className="mr-2">{"(Country " + smartDevice.CountryCode + ")"}</div>
            </div>

            <div className="flex flex-row items-center">
                <div className="text-sm font-semibold mr-2">Location enabled: </div>    
                <div className="mr-2">{smartDevice.IsLocationEnabled ? "Enabled" : "Disabled"}</div>
            </div>

            <div className="flex flex-row items-center">
                <div className="text-sm font-semibold mr-2">Bluetooth enabled: </div>    
                <div className="mr-2">{smartDevice.BluetoothState}</div>
            </div>

            <div className="flex flex-row items-center">
                <div className="text-sm font-semibold mr-2">Smart Device Id: </div>    
                <div className="">{app.SmartDeviceId}</div>
            </div>

            {smartDevice.DoseDevices.length > 0 && <>
                <div className="text-sm font-semibold mr-2">Dose devices: </div>
                {smartDevice.DoseDevices.map(device => {
                    return <div key={device.DoseDeviceId}>
                        {"Id: " + device.DoseDeviceId + " UUID: " + device.DoseDeviceUuid}
                    </div>
                })}
            </>}
        </>}

    </div>)
}

App.propTypes = {
    app: PropTypes.object.isRequired
}

Apps.propTypes = {
    userId: PropTypes.string.isRequired
}

export default Apps;