import React, { useEffect, useState, useRef } from 'react';
import Message from './chatMessageComponent';
import PropTypes from 'prop-types';

const Chat = (props) => {
  const [newMessage, setNewMessage] = useState('');

  const messages = props.messages;
  const inputRef = useRef();
  const bottomListRef = useRef();

  const chatUserId = props.chatUserId;
  const getMessageBackgroundColor = (message) => {
    return message.userId != chatUserId ? "bg-soft-blue" : "bg-quarternary";
  };

  const getMessageAlignment = (message) => {
    return message.userId != chatUserId ? "self-end" : "self-start";
  };

  const getMessageRounding = (message) => {
    return message.userId != chatUserId ? "rounded-b-lg rounded-l-lg" : "rounded-b-lg rounded-r-lg";
  };

  const getMessageTextAlignment = (message) => {
    return message.userId != chatUserId ? "text-right" : "text-left";
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  useEffect(() => {
    if (bottomListRef.current) {
      bottomListRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const handleOnChange = e => {
    setNewMessage(e.target.value);
  };

  const handleOnSubmit = e => {
    e.preventDefault();

    const trimmedMessage = newMessage.trim();
    if (trimmedMessage) {
      props.onSubmit({
        text: trimmedMessage,
      });
      setNewMessage('');
    }
  };

  const className = props.className ?? "h-screen"
  return (
    <div className={"flex flex-col " + className}>
      {props.userName && <div className="w-full text-center my-2 text-2xl text-wedgewood-600 font-semibold">{"Chatting with " + props.userName}</div>}
      <div className="h-screen overflow-y-hidden">
        <div className="h-full overflow-auto py-4 max-w-screen-lg mx-auto">
          <ul className="flex flex-col">
            {messages
              ?.sort((first, second) =>
                first?.createdAt?.seconds <= second?.createdAt?.seconds ? -1 : 1
              )
              ?.map(message => (
                <li key={message.id} className={`${getMessageAlignment(message)} ${getMessageBackgroundColor(message)} ${getMessageRounding(message)} my-1 mx-1`} >
                  <Message {...message} alignment={getMessageTextAlignment(message)}/>
                </li>
              ))}
          </ul>
          <div ref={bottomListRef} />
        </div>
      </div>
      <div className="mb-6 mx-4">
      <form
          onSubmit={handleOnSubmit}
          className="flex flex-row text-white bg-wedgewood-600 select-none rounded-md px-4 py-3 z-10 max-w-screen-lg mx-auto shadow-md outline-none"
        >
          <input
            ref={inputRef}
            type="text"
            value={newMessage}
            onChange={handleOnChange}
            placeholder="Type your message here..."
            className="flex-1 bg-transparent focus:ring-transparent sm:text-sm text-white border-none placeholder-white"
          />
          <button
            type="submit"
            disabled={!newMessage}
            className="uppercase font-semibold text-sm tracking-wider text-white transition-colors"
          >
            Send
          </button>
        </form>
      </div>
    </div>
  );
};

Chat.propTypes = {
  messages: PropTypes.array,
  chatUserId: PropTypes.string,
  onSubmit: PropTypes.func,
  userName: PropTypes.string,
  className: PropTypes.string,
}

export default Chat;