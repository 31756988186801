import React from "react"
import { navigate } from "gatsby"
import { auth } from "../firebase";
import { useAuthState } from 'react-firebase-hooks/auth';
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';
import { getIsUserDataInitialized, getPermissions } from "../store/userDataSlice";

const PrivateRoute = ({ component: Component, location, permission, ...rest }) => {    
    const [ user, userLoading ] = useAuthState(auth);
    const permissions = useSelector(getPermissions);
    const userDataInitialized = useSelector(getIsUserDataInitialized);

    if (userLoading) {
        // firebase user is not loaded
        return null;
    }
    
    if (user == null && location.pathname !== '/login') {
        // no user is authenticated to the app
        navigate("/login")
        return null;
    }

    if (!userDataInitialized) {
        // firebase user is loaded but permissions and settings are still loading
        return null;
    }    

    let enoughPermissions = typeof permission === "undefined" || typeof permission !== "undefined" && permissions.indexOf(permission) !== -1;
    if (!enoughPermissions && location.pathname !== '/login') {
        navigate("/403");
        return null;
    }
    return <Component {...rest} />;
}

const PrivateRouteComponent = (props) => {
    return typeof window !== "undefined" ? <PrivateRoute {...props} /> : null;
}

PrivateRoute.propTypes = {
    component: PropTypes.func,
    location: PropTypes.object,
    permission: PropTypes.string,
  };

export default PrivateRouteComponent
