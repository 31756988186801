import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Medication from "./medication";
import parse from "date-fns/parse";
import { getMultiUserDocuments, getCollection } from "../../../firebase/firestoreUtils"
import endOfDay from 'date-fns/endOfDay';
import Loader from "../../loader";

const Medications = ({ userId, schemaVersion }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [medications, setMedications] = useState([]);

    useEffect(() => {
        async function loadData() {
            try {
                setIsLoading(true);
                // meds
                const meds = await getMultiUserDocuments("medications", schemaVersion, userId).get();
                let medicationsData = meds.docs.map(doc => { return { Id: doc.id, ...doc.data() }});
                // places and dose devices
                if (schemaVersion >= 8) {
                    const medicationPlaces = await getCollection("medications_places").where("UserId", "==", userId).get();
                    const medicationPlacesData = medicationPlaces.docs.map(x => x.data());
                    const medicationSettings = await getCollection("medication_settings").where("UserId", "==", userId).get();
                    const medicationSettingsData = medicationSettings.docs.map(x => x.data());

                    const tempMedicationsData = []
                    for (const medData of medicationsData) {
                        const settings = medicationSettingsData.find(x => x.MedicationId == medData.Id);
                        const medPlacesData = medicationPlacesData.find(x => x.MedicationId == medData.Id);
                        tempMedicationsData.push({ ...medData, 
                            ContinuousNotificationsEnabled: settings?.ContinuousNotificationsEnabled ?? false, 
                            IsAlwaysWithMe: medPlacesData?.IsAlwaysWithMe ?? false,
                            Places: medPlacesData?.Places ?? [],
                            DoseDevices: medPlacesData?.DoseDevices ?? [],
                            IsCritical: settings.IsCritical,
                         });
                    }
                    medicationsData = tempMedicationsData
                }

                const places = await getCollection("places").where("UserId", "==", userId).get();
                const placesData = places.docs.map(doc => { return { Id: doc.id, ...doc.data() }});

                const doseDevices = await getCollection("dose_devices").where("UserId", "==", userId).get();
                const doseDevicesData = doseDevices.docs.map(doc => { return { Id: doc.id, ...doc.data() }});

                const medicationsResult = []
                for (let med of medicationsData) {
                    const medPlaces = placesData.filter(p => med.Places.includes(p.Id));
                    const medDevices = doseDevicesData.filter(d => med.DoseDevices.includes(d.Id));
                    med.PlacesData = medPlaces;
                    med.DoseDevicesData = medDevices;
                    medicationsResult.push(med);
                }

                setMedications(medicationsResult);
            }
            catch (e) {
                console.log("Medications loading error");
                console.error(e);
            }
            finally {
                setIsLoading(false);
            }
        }

        loadData();
    }, []);

    const activeMeds = [];
    const inactiveMeds = [];
    const asNeededMeds = [];
    for (const med of medications) {
        if (med.Pattern == null) {
            activeMeds.push(med);
            continue;
        }

        const asNeeded = med.AsNeeded != null && med.AsNeeded;
        const isInactive = med.Pattern.EndDate && parse(med.Pattern.EndDate, 'yyyy-MM-dd', new Date()) < endOfDay(Date.now());
        if (asNeeded == true) {
            asNeededMeds.push(med);
        }
        else if (isInactive) {
            inactiveMeds.push(med);
        } else {
            activeMeds.push(med);
        }
    }
    
    return <div className="h-full flex-grow-0 overflow-y-auto mx-4 px-6">
        {isLoading && <Loader />}
        {!isLoading && medications.length == 0 && <div className="text-xl my-3">Nothing to show here!</div>}
        {activeMeds.length > 0 && <div>
            <div className="text-xl font-semibold my-3">Active:</div>
            {activeMeds.sort((a,b) => a.Name.localeCompare(b.Name)).map(med => {
                return <Medication key={med.Id} userId={userId} medication={med} isActive={true}/>
            })}
        </div>}
        {asNeededMeds.length > 0 && <div>
            <div className="text-xl font-semibold mb-3 mt-5">As needed:</div>
            <div className="h-px bg-wedgewood-500 opacity-50"/>
            {asNeededMeds.sort((a,b) => a.Name.localeCompare(b.Name)).map(med => {
                return <Medication key={med.Id} userId={userId} medication={med} isActive={true}/>
            })}
        </div>}
        {inactiveMeds.length > 0 && <div>
            <div className="text-xl font-semibold mb-3 mt-5">Inactive:</div>
            <div className="h-px bg-wedgewood-500 opacity-50"/>
            {inactiveMeds.sort((a,b) => a.Name.localeCompare(b.Name)).map(med => {
                return <Medication key={med.Id} userId={userId} medication={med} isActive={false}/>
            })}
        </div>}
    </div>
}

Medications.propTypes = {
    userId: PropTypes.string.isRequired,
    schemaVersion: PropTypes.number.isRequired
}

export default Medications;