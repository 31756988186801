import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types';
import ChatComponent from "../../../pagesComponents/chat"
import { ChatAlt2Icon } from "@heroicons/react/solid"
import { createChat, getChatByUserId } from "../../../store/chatsSlice";

const Chat = ({ userId, schemaVersion }) => {
    const dispatch = useDispatch();
    const [error, setError] = useState("");
    const chat = useSelector(state => getChatByUserId(state, userId));

    async function startChat(setError) {
        try {
            let userIdTrimmed = userId.trim();
            await dispatch(createChat(userIdTrimmed)).unwrap();
        } catch (e) {
            console.log(e)
            setError(e.message);
        }
    }

    if (schemaVersion < 7) {
        return <div className="mx-8 my-5 text-xl text-gray-500">{"The user's app doesn't support chat!"}</div>
    }

    return <>
        {chat && <div className="h-full flex-grow-0 overflow-y-auto my-1">
            <ChatComponent id={chat.Id} className="h-full"/>
        </div>}

        {/* if the chat doesn't exist, then create it */}
        {!chat && <div className="h-full flex-grow-0 overflow-y-auto mx-4 my-2">
            <div className="mx-4 my-3 text-xl text-gray-500 break-words">{"Chat is not started for the user."}</div>

            <button onClick={() => { startChat(setError) }} 
                className="text-sm text-center font-semibold rounded-full text-white bg-wedgewood-600 flex flex-row items-center px-6 py-2 mx-4 my-3" >
                    <ChatAlt2Icon className="h-6 w-6 mr-1"/>
                    <div>Start Chat</div>
            </button>

            {error && <div className="text-xl font-medium text-alert-red my-2 text-start">{error.toString()}</div>}
        </div>}
    </>
}

Chat.propTypes = {
    userId: PropTypes.string.isRequired,
    schemaVersion: PropTypes.number.isRequired,
}

export default Chat;